(function($) {

	$(document).ready(function(){

		$(".banner-item").hide();
		
	  $.fn.random = function() {
		return this.eq(Math.floor(Math.random() * this.length));
		}

		$(".banner-item").random().show();

	  
		$(".benefits-box").hover(
		  function() {
		    $(this).find(".box-overlay").css("opacity", "1");
		  }, function() {
		    $(this).find(".box-overlay").css("opacity", "0");
		  }
		)

		    
		$(".benefits-box").on("click",function(){
		  	$(this).find(".box-overlay").css("opacity", "1");
		  }, function() {
		    $(this).find(".box-overlay").css("opacity", "0");
		  }
		)

		// Slider reference
		$('.references .slick-container').slick({
		  infinite: true,
		  autoplay: false,
		  fade: true,
		  appendArrows: $(".slick-controls"),
		  prevArrow: '<button class="slick-prev btn btn-light"><i class="csico csico-arrow-left"></i></button>',
		  nextArrow: '<button class="slick-next btn btn-light"><i class="csico csico-arrow-right"></i></button>',
		});

		$('.slick-banner').slick({
		  autoplay: false,
		  fade: true,
		  arrows: false,
		});

		$('.banner .banner-item, .benefits-box-image .inner').each(function(){
			$(this).children('img').hide();
      $(this).css('background-image', 'url(' + $(this).children('img').attr('src') + ')');
    });
	    
	})

	$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .not('.faq-toggle')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 0
        }, 500, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });

  // Back top button
  $(document).ready(function(){
     $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('#back-to-top').tooltip('hide');
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });    
  });

})( jQuery );